:root{
    --primary:#21864b !important;
   /* --primary:#16be3a; */
    --primary-two: #16be3a;
    --primary-three: #43F46A;
    --text:#72849a;
    --active-list:#455560;
    --danger-alt:#ff6b72;
}

.file_upload_sec {border: 1px dashed gray; height: 150px;cursor: pointer;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon,
.file_upload_sec .img_icon_right{width: 50px;height: 50px;display: flex;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon_right{filter: grayscale(1);opacity: 0.6;}

.file_upload_sec .img_icon_left{right: -50px;transition: all 0.30s ease;}
.file_upload_sec .img_icon_right{left: -50px;transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_left{right: -20px;transform: rotate(-20deg);transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_right{left: -20px;transform: rotate(20deg);transition: all 0.30s ease;}

.file_upload_sec .img_icon{z-index: 1;}
.file_upload_sec .img_icon img{width: 100%;height: 100%;object-fit: contain; object-position: center;}
.file_upload_sec .uploaded_img{object-fit: cover;object-position: center;}

.file_upload_input{width: 100%;height: 100%;opacity: 0;z-index: 2;cursor: pointer;}
.img_delete_sec{top:0;left: 0;background-color: rgba(174, 173, 173, 0.41);}
.delete_btn{border: 1px solid #ff6b72;display: flex;align-items: center;justify-content: center;width: 40px; height: 40px;border-radius: 50%;background-color: #ff6b72;transition:all 0.30s ease;}
.delete_btn:hover{scale: 1.2;transition:all 0.30s ease;}

.bg-image{background-color: red; background-repeat: no-repeat;width: 100%;background-size: cover;}

/* .ant-table-nested-thead th{background-color: #72849a!important; color: #f0eaeaf0 !important;} */
/* .ant-table-thead th{background-color: #21864b!important; color: #f0eaeaf0 !important;} */
/* ----delete popup---- */
.delete_popup{background-color: rgba(0, 0, 0, 0.255);z-index: 1000;}
.delete_popup .popup{border-radius: 5px;width: 300px;}
.delete_popup .popup .dialogue{line-height: 20px;}
.delete_popup .popup .del_btn{background-color: var(--danger-alt);}
.delete_popup .popup .del_btn:focus-visible{outline: 4px solid #ff848ab1;}
.delete_popup .popup .del_btn:hover{border: 1px solid #ff757c!important;}

.show{display: flex;}
.hide{visibility: hidden!important;opacity:0;transition:all 0.30s ease;}
.left-0{left: 0;}
.top-0{top: 0;}

.custom-input:focus {
    border-color: #efea9a; 
    /* Replace with your desired color */
  }

  .my-custom-scrollbar::-webkit-scrollbar {
    width: 5px;
  }
  
  .my-custom-scrollbar::-webkit-scrollbar-track {
    background: #f9c1c1;
  }
  
  .my-custom-scrollbar::-webkit-scrollbar-thumb {
    background: #04340c;
  }
  
  .my-custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #031201;
  }



  .text-editor-sec{scroll-behavior: smooth;}
.text-editor-sec::-webkit-scrollbar,
.text-editor-sec *::-webkit-scrollbar{width: 6px;height:0;background-color: transparent;margin-left:4px}
.text-editor-sec::-webkit-scrollbar-thumb,
.text-editor-sec *::-webkit-scrollbar-thumb{width: 100%;background-color: #ef233c;border-radius: 5px;}
.text-editor-sec::-webkit-scrollbar-corner,
.text-editor-sec *::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}
.text-editor-sec .ce-block__content{background-color: #feebed;border-radius: 5px;margin-bottom: 3px;padding:0 8px}
.ce-toolbar__plus{background-color: #ef233c;color:white;margin-right:3px}
.ce-toolbar__plus:hover{background-color: #fbc9cf;color:#ef233c;border:1px solid #ef233c}
.cdx-search-field.ce-popover__search{background-color: #fbc9cf81;}
.cdx-search-field__input{border: 1px solid #ef233c;padding: 3px;border-radius: 3px;}
.cdx-search-field__input:focus{--tw-ring-color:#ef233c;border-color: #ef233bbc;}
.ce-popover__items{padding-right: 5px;}
.ce-popover-item{padding:4px;}
.ce-conversion-toolbar.ce-conversion-toolbar--showed{padding-left: 5px;padding-right: 5px;}
.ce-conversion-tool{margin-bottom: 3px;border-radius: 3px;}
.ce-conversion-tool:hover,
.ce-popover-item:hover{background-color: #ef233c!important;color:white}

.ce-conversion-tool:hover .ce-conversion-tool__icon,
.ce-popover-item:hover .ce-popover-item__icon{background-color: white!important;color:#ef233c}


.cdx-list__item{line-height: 3px;}

.ce-conversion-tool.ce-conversion-tool--focused,
.selection-list-option-active,
.ce-popover-item--active{background-color: #ef233c!important;color:white!important;}

.ce-conversion-tool.ce-conversion-tool--focused .ce-conversion-tool__icon,
.ce-popover-item--active .ce-popover-item__icon{background-color: white!important;color:#ef233c}
.ce-inline-toolbar__buttons{align-items: center;padding: 5px;}
.ce-inline-toolbar__buttons button{border:1px solid #ef233c;color: #ef233c;height:32px;width:32px;border-radius: 4px;margin-left: 5px;}

.ce-inline-tool.ce-inline-tool--font .button-wrapper-text{font-size: 12px;color: #ef233c;font-weight: 600;}
.ce-inline-toolbar__buttons button:hover,
.ce-inline-toolbar__buttons button:hover .button-wrapper-text{background-color: #ef233c;color: white!important;}
.ce-inline-toolbar__toggler-and-button-wrapper{border: 1px solid #ef233c;border-radius: 5px;}
.ce-inline-toolbar__dropdown{border-right-color: #ef233c;background-color: #ef233c;color: white;}
.ce-inline-toolbar__dropdown:hover{background-color: transparent;color: #ef233c;}
.ce-inline-tool.ce-inline-tool--font .icon--toggler-down{display: none!important;}
.ce-conversion-toolbar__label{color:#ef233c}

.ce-inline-tool.ce-inline-tool--font .selectionList{margin-top: 5px;}
.ce-inline-tool.ce-inline-tool--font .selectionList .selection-list-wrapper{border: 1px solid #ef233c!important;border-radius: 4px!important;}
.ce-inline-tool.ce-inline-tool--font .selectionList .selection-list-wrapper div.selection-list-option{color: #ef233c;font-size: 12px;}
.ce-inline-tool.ce-inline-tool--font .selectionList .selection-list-wrapper div.selection-list-option:hover{background-color: #ef233c!important;color: white!important;}


/* .portfolioImage :where(.css-dev-only-do-not-override-zggqhc).ant-image {
  position: relative;
  display: inline-block;
  width: 100% !important;
  height: 100% !important;
}
.portfolioImage :where(.css-dev-only-do-not-override-zggqhc).ant-image .details-delete {
  position: absolute !important; top: 20% !important; z-index: 99 !important; right: 5% !important;
} */

::-webkit-scrollbar {
  width: 12px; 
}

::-webkit-scrollbar-thumb {
  background-color: #21864b;
  border-radius: 6px; 
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}


.newScroll html {
  scrollbar-width: thin; 
  scrollbar-color: #21864b #f1f1f1;
}






/* / --------------------------------------sidebar styles------------------------------------------- // */
.bg-primary {
  background-color: var(--primary) !important;
}
.text-primary {
  color: var(--primary) !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: var(--primary) !important;
}
.bg-custom-1 {
  background-color: #ffffff !important;
  transition: all 0.3s ease;
}
.bg-primary {
  background-color: var(--primary) !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #cefbd7 !important;
}

.ant-menu-light .ant-menu-item-selected {
  color: rgb(255, 255, 255) !important;
}
.ant-menu-light .ant-menu-item-selected {
  background-color: var(--primary);
}
.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after {
  border-color: var(--primary);
}
.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #312e2e !important;
}
.ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ),
.ant-menu-light
  .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  background-color: var(--primary) !important;
  color: white;
}
.ant-menu-light {
  color: var(--primary);
}
.ant-btn-primary {
  background-color: var(--primary) !important;
}
.ant-ribbon {
  background-color: var(--primary) !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color:  var(--primary-two)  !important;
}
.ant-btn-primary:not(:disabled):hover {
  background-color:  var(--primary-two)!important;
}
.ant-btn-default:not(:disabled):hover {
  color: white;
  border-color: var(--primary) !important;
}
.border-btn:not(:disabled):hover {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-picker-focused,
.ant-picker:hover,
.ant-select:hover,
.ant-select:focus,
.ant-select.ant-select-in-form-item.ant-select-multiple.ant-select-show-arrow.ant-select-open.ant-select-show-search:hover,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--primary) !important;
}
.text-editor .ql-container:hover,
.text-editor .ql-container:focus-within {
  border: 1px solid var(--primary) !important;
  border-radius: 0 0 10px 10px;
}
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
  stroke: var(--primary) !important;
}
.ql-toolbar.ql-snow .ql-picker-label:hover {
  color: var(--primary) !important;
}
.ql-snow.ql-toolbar button:hover > * {
  stroke: var(--primary) !important;
}
.ant-spin .ant-spin-dot-item {
  background-color: var(--primary) !important;
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: var(--primary);
}
.ant-picker-dropdown .ant-picker-header-view button:hover,
.ant-picker-dropdown .ant-picker-today-btn:hover {
  color: var(--hover);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: var(--primary);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: var(--primary);
}
.more-btn {
  background-color: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
}
.more-btn:hover {
  border-color: transparent;
}
.ant-pagination .ant-pagination-item-active {
  background-color: var(--primary);
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--accent);
}
.ant-btn-dangerous:hover {
  background-color: var(--danger-alt);
  border: none !important;
  color: white;
}
.ant-select-focused .ant-select-selector,
.ant-select-open.ant-select-show-search .ant-select-selector {
  border: 1px solid var(--primary) !important;
}
.ant-input-affix-wrapper-focused,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: var(--primary) !important;
}
h5.input_img_text > span.text-info {
  color: var(--primary) !important;
}
.ant-spin {
  color: var(--primary);
}
.border-button:hover,
.edit-button:hover {
  color: var(--primary) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: var(--primary) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--accent);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-checkbox-checked:after {
  border-color: var(--primary) !important;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--primary) !important;
}
.ant-switch.ant-switch-checked {
  background-color: var(--primary);
}
.example-text-textarea > div > div > div:first-child {
  max-width: 100%;
}
.chat .chat-content .chat-content-body .msg.msg-sent .bubble .bubble-wrapper {
  background-color: var(--primary);
}
.ant-dropdown-menu-item:has(.chat-dropdown-list) {
  padding: 3px 15px !important;
}
.ant-dropdown-menu-item .chat-dropdown-list {
  font-size: 12px !important;
}
.ant-dropdown-menu-item:has(.chat-dropdown-list):hover {
  background-color: var(--accent) !important;
}
.ant-dropdown-menu-item:has(.chat-dropdown-list.remove):hover {
  background-color: var(--primary-two) !important;
  color: white;
}

/* --------------------------------table styling----------------------------------- */
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-item-link {
  color: #154eeb !important;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
  color: #cbf5e9 !important;
}

.ant-layout{background: #f5f8fc;}
.custom-shadow{box-shadow: 0 4px 6px -1px #0000001a;}
.dashboard_latestjob{box-shadow: 0 4px 6px -1px #0000001a;}
.dashboard_latestjob .latest_job_table thead tr th{background: #0f172a; color: #fff; padding: 10px 20px;}
.latest_job_table .ant-table-row .ant-table-cell a{color: #000;}
.dashboard_latestjob h6{font-size: 22px; font-weight: 500; border-bottom: 1px solid #f1f1f1; padding: 0 0 6px; margin: 0 0 20px;}
.classified_type h6{font-size: 22px; font-weight: 500; border-bottom: 1px solid #f1f1f1; padding: 0 0 6px;}
.recent_company h6{font-size: 22px; font-weight: 500; border-bottom: 1px solid #f1f1f1; padding: 0 0 6px; margin: 0 0 20px;}
.latestproduct{box-shadow: 0 4px 6px -1px #0000001a;}
.latestproduct h6{font-size: 22px; font-weight: 500; border-bottom: 1px solid #f1f1f1; padding: 0 0 6px; margin: 0 0 20px;}
.latestproduct .latestproducttable thead tr th{background: #0f172a; color: #fff; padding: 10px 20px;}
.latestevent h6{font-size: 22px; font-weight: 500; border-bottom: 1px solid #f1f1f1; padding: 0 0 6px; margin: 0 0 20px;}
.latestevent .latesteventtable thead tr th{background: #0f172a; color: #fff; padding: 10px 20px;}
.latestpost h6{font-size: 22px; font-weight: 500; border-bottom: 1px solid #f1f1f1; padding: 0 0 6px; margin: 0 0 20px;}
.latestpost .latestposttable thead tr th{background: #0f172a; color: #fff; padding: 10px 20px;}
.recentuser h6{font-size: 22px; font-weight: 500; border-bottom: 1px solid #f1f1f1; padding: 0 0 6px;}
.jobapplication h6{font-size: 22px; font-weight: 500; border-bottom: 1px solid #f1f1f1; padding: 0 0 6px; margin: 0 0 20px;}
.jobapplication .jobapplicationtable thead tr th{background: #0f172a; color: #fff; padding: 10px 20px;}
.jobapplication .jobapplicationtable{border: 1px solid #f1f1f1; border-radius: 11px 11px 0px 0px;}
.card.user-bg{border-bottom:0; border-left: 3px solid #11a2e7 !important; border-right: 0; border-top: 0;}
.user-bg .dashboardCardTitle{color: #050505; font-size: 13px; font-weight: 500;}
.user-bg span.h3{color: #000000; font-weight: 400;}
.post-bg{border-bottom:0; border-left: 3px solid #feb31b !important; border-right: 0; border-top: 0;}
.post-bg .dashboardCardTitle{color: #050505; font-size: 13px; font-weight: 500;}
.post-bg span.h3{color: #000000; font-weight: 400;}
.job-bg{border-bottom:0; border-left: 3px solid #f11f92 !important; border-right: 0; border-top: 0;}
.job-bg .dashboardCardTitle{color: #050505; font-size: 13px; font-weight: 500;}
.job-bg span.h3{color: #000000; font-weight: 400;}
.event-bg{border-bottom:0; border-left: 3px solid #21864b !important; border-right: 0; border-top: 0;}
.event-bg .dashboardCardTitle{color: #050505; font-size: 13px; font-weight: 500;}
.event-bg span.h3{color: #000000; font-weight: 400;}
.product-bg{border-bottom:0; border-left: 3px solid #d9010b !important; border-right: 0; border-top: 0;}
.product-bg .dashboardCardTitle{color: #050505; font-size: 13px; font-weight: 500;}
.product-bg span.h3{color: #000000; font-weight: 400;}
.jobapplication-bg{border-bottom:0; border-left: 3px solid #a136fa !important; border-right: 0; border-top: 0;}
.jobapplication-bg .dashboardCardTitle{color: #050505; font-size: 13px; font-weight: 500;}
.jobapplication-bg span.h3{color: #000000; font-weight: 400;}
.bottombar button{box-shadow: none;}
.revenue h4{font-size: 22px; font-weight: 500; color: #000;}
.ant-card-body .jobapplicationtable tbody tr td {padding: 9px 18px;}
.latestproducttable .avatar-status .ant-avatar{border-radius: 50px;}
.latestevent .avatar-status .ant-avatar{border-radius: 50px;}
.latestpost .avatar-status .ant-avatar{border-radius: 50px;}

/*------------------ sidebar menu ----------------------*/
.ant-menu{padding: 0 14px;}
.ant-menu-item.ant-menu-item-selected{border-radius: 7px;}
.ant-menu-item.ant-menu-item-active{border-radius: 7px;}
.ant-layout .ant-layout-sider{background: #0f172a !important; padding-bottom: 16px; height: calc(100vh - 48px);}
.ant-menu-item-group-title span{color: #959595;}
span.ant-menu-title-content span{color: #fff; font-weight: 300;}

/*------------------ admin list ------------------------*/
.adminlisttable table thead tr th{background: #0f172a !important; color: #fff !important; padding: 10px 20px !important;}
.adminlisttable table thead tr th .anticon-caret-up{color: #fff;}
.adminlisttable table thead tr th .anticon-caret-down{color: #fff;}
.ant-table-filter-column .anticon-filter{color: #fff;}
.adminlisttable table{border: 1px solid #f1f1f1; box-shadow: 0 4px 6px -1px #0000001a;}
.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell{background: rgb(33 134 75 / 10%);}
.anticon svg{width: 100%;}
.userlisttablewrapper{margin: 21px 0 0;}
.admincard-header .ant-btn.ant-btn-block{width: auto;}

/*------------------ admin edit ------------------------*/
.adminform-wrapper h2{font-size: 24px; font-weight: 700;}
.ant-tabs-tabpane{overflow: hidden; padding: 15px 21px; border-radius: 10px; box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.2);}
.file_upload_sec{width: 300px !important; height: 300px; border-radius: 50% !important;}
.inner-upload-img{border-radius: 50%; height: 300px !important; width: 300px !important; text-align: center; margin: 0 auto;}
.adminimageupload{border-left: 1px dashed #dddcdc; padding-left: 35px !important;}
.adminformwrapper{padding-right: 40px !important;}
.basicadmindetails .ant-form-item-label label{font-weight: 500; color: #64748b;}
.basicadmindetails input{border-radius:5px; border: 1px solid #d5d5d5;}
.basicadmindetails span{border-radius:5px;}
.admincard-header{display: flex; align-items: center; justify-content: space-between; border-bottom: 1px dashed #e7e7e7; margin: 0 0 15px; padding: 0 0 15px;}
.admincard-header h6{font-size: 26px; font-weight: 600; color: #000; margin: 0;}
.admincard-header .addnewbtn{width: 100px;}
.bottombar label{margin: 0; color: #64748b; font-weight: 500; font-size: 15px;}
.bottombar .ant-select .ant-select-selector{border: 1px solid #cfcfcf;}
.bottombar .ant-input-affix-wrapper{border: 1px solid #cfcfcf;}
.bottombar .ant-input-affix-wrapper:focus{box-shadow: none; border: 1px solid #cfcfcf;}
.bottombar .ant-select:focus{box-shadow: none; border: 1px solid #cfcfcf;}
.bottombar .ant-btn.ant-btn-block{width: auto;}

/*------------------ user list ------------------------*/
.userlisttablewrapper .userlisttable thead tr th{background: #0f172a !important; color: #fff !important; padding: 10px 20px !important;}
.userlisttablewrapper .userlisttable .anticon-caret-up, .anticon-caret-down, .anticon-filter{color: #dfdfdf; font-size: 10px;}
.ant-table-wrapper .ant-table-column-title{font-size: 12px; text-transform: uppercase; font-weight: 500;}
.ant-table-wrapper table{border: 1px solid #efefef;}
.userlist-wrap label{margin: 0; color: #64748b; font-weight: 500; font-size: 15px;}
.searchbox .refresh-btn{width:60px; border-radius: 6px; box-shadow: none; border: 0;}
.searchbox .refresh-btn:focus{outline: none;}
.searchbox{align-items: end; margin: 0 0 21px !important;}
.addbtn{border-radius: 6px; box-shadow: none; border: 0;}
.addbtn:focus{outline: none;}
.admincard-header button{border-radius: 6px; box-shadow: none;}
.searchbox .ant-select .ant-select-selector{border: 1px solid #d5d5d5; border-radius: 5px;}
.searchbox .ant-input-affix-wrapper{border: 1px solid #d5d5d5; border-radius: 5px;}
.ant-space .ant-space-item .ant-input-affix-wrapper{border: 1px solid #d5d5d5; border-radius: 5px;}
.ant-input-affix-wrapper input::placeholder{color: #000;}
.ant-select-selector .ant-select-selection-placeholder{color: #000;}

/*----------------------- user edit -----------------------*/
.usereditform h2{font-size: 24px; font-weight: 700;}
.useraddbtn{border-radius: 6px; box-shadow: none;}
.userinputform label{margin: 0; color: #64748b; font-weight: 500; font-size: 15px;}
.userinputform .ant-input{border: 1px solid #d5d5d5; border-radius: 5px;}
.userimageupload{border-left: 1px dashed #dddcdc; padding-left: 35px !important;}
.usereditform .ant-row > .ant-col{padding-right: 37px;}

/*----------------------- latest products --------------------*/
.latestproducttable tbody tr td{padding: 10px 17px !important;}
.latestevent tbody tr td{padding: 10px 17px !important;}
.latestpost tbody tr td{padding: 10px 17px !important;}

/*----------------------- user details ---------------------*/
.user-details-wrapper .user-image{background: #ffffff; box-shadow: 0 4px 6px -1px #0000001a; border-radius: 15px; height: 273px; overflow: hidden; border: 2px solid #f5f5f5; padding: 0 !important;}
.user-icon span:first-child { width: 24px; height: 24px; background: #f9f9f9; border-radius: 5px;}
.user-image .avatar-status span{width: 100% !important; height: 100% !important; border-radius: 0;}
.user-details .ant-card{margin: 0; display: flex; width: 100%;}
.user-details .ant-card .ant-card-body{width: 100%;}
.ant-ribbon.ant-ribbon-placement-end{inset-inline-end: 3px;}
.media-links{display: flex; justify-content: space-between; align-items: center; margin: 20px 0 18px !important; padding-left: 8px;}
.media-links .ant-col{width: 365px; padding: 0 !important;}
.media-links .ant-col .ant-tag{border-radius: 6px !important; padding: 4px 12px !important; font-size: 14px !important;}
.ant-ribbon-wrapper .ant-ribbon{font-size: 13px;}
.ant-ribbon-wrapper > div:first-child{font-size: 17px !important;}
.userimagetags{margin-top: 0; width: 100%; display: flex;}
.portfolioImage .ant-image{display: inline-block; width: 100%; aspect-ratio: 4 / 3; border-radius: 5px; overflow: hidden;}
.userdetailtab .ant-tabs-tabpane{padding: 0;}
.userdetailtab .ant-card{margin-bottom: 0px; background: #f9f9f9;}
.address-tab span{color: #000 !important;}
.address-tab .ant-row{border-bottom: 1px dashed #dddddd; margin: 0 0 19px !important;}
.tabfriend-wrapper .ant-image{aspect-ratio: 1 / 1; width: 100%; border-radius: 12px; overflow: hidden; border: 2px solid #e1e1e1;}
.frienddescription{padding-left: 22px;}
.frienddescription h2{font-size: 20px; color: #000;}
.frienddescription h5{font-size: 16px; color: #000;}
.userdetailtab .ant-card-bordered{border: none;}
.friendtabheader .headingfriend h3{font-weight: 700; color: #000; font-size: 17px;}
.site-layout-content{display: inline-block; width: 100%;}
.activityhedaing .headingfriend h3{font-weight: 700; color: #000; font-size: 17px; margin: 0 0 21px;}
.chatlayout h2.headingchat{font-weight: 700; color: #000; font-size: 17px; margin: 0 0 21px;}
.chatlayout .ant-menu{padding: 0 14px 0 0;}
.friendlistpagination{display: flex; justify-content: right; width: 100%; margin: 0 0 17px;}
.companydetail {overflow: hidden; box-shadow: 0 4px 6px -1px #0000001a; border-radius: 6px; padding: 0 !important;}
.companydetail .ant-image{width: 100%; height: 100%;}
.imageWrap.companyImage { max-width: 100% !important; height: 100% !important;}
.imageWrap.companyImage .ant-image { display: inline-block; width: 100%; aspect-ratio: 4 / 3; border-radius: 5px; overflow: hidden;}
.portfolioImage{width: 100% !important; height: 100% !important;}
.address-tab{margin-bottom: 0; border: none; width: 100%;}
.address-tab svg{color: #11a151;}
#rc-tabs-7-panel-2{padding: 0;}
.companydetailstab .ant-card .ant-card-body:first-child{padding: 0;}
.companydetailstab .ant-card .ant-card-body .ant-col{padding: 0 !important;}
.companydetailstab .ant-card .ant-card-body .ant-col .ant-card{margin-bottom: 0;}
#rc-tabs-7-panel-1{padding: 0;}
#rc-tabs-7-tab-3{padding: 0;}
.userdetailtab .layout{min-height: auto;}
.userdetailtab .ant-card-body{background: #f9f9f9; padding: 15px 9px;}
.companydetail_tabarea .ant-tabs-tabpane{padding: 0;}
.companydetailstab .ant-card{background: #f9f9f9; border: none;}
.user-details-wrapper .media-body .ant-typography:nth-child(2){font-size: 15px; font-weight: 400; margin: 20px 0 20px;}
.user-image .ant-image{height: 100%; width: 100%; margin-bottom: auto;}
.photos .ant-card{margin-bottom: 0px;}
.education{background: #f9f9f9;}
.userdetailsmain_wrapper .ant-tabs-tabpane{box-shadow: none; padding: 0;}
.education_wrapper .ant-col .ant-card .ant-card-body{background: #fff; border-radius: 10px; box-shadow: 0 4px 6px -1px #0000001a; border: 1px solid #69e19b;}
.education_wrapper svg{color: #11a151;}
.qualification {display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #f1f1f1; margin: 0 0 5px; padding: 0 0 6px;}
.qualification p {margin: 0;}
.university{display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #f1f1f1; margin: 0 0 5px; padding: 0 0 6px;}
.university p{margin: 0;}
.startdate{display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #f1f1f1; margin: 0 0 5px; padding: 0 0 6px;}
.startdate p{margin: 0;}
.enddate{display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #f1f1f1; margin: 0 0 5px; padding: 0 0 6px;}
.enddate p{margin: 0;}
.userdetailtab .description_wrap{background: #fff; border-radius: 10px; box-shadow: 0 4px 6px -1px #0000001a; border: 1px solid #69e19b; overflow: hidden;}
.experience_description{display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #f1f1f1; margin: 0 0 5px; padding: 0 0 6px;}
.experience_description p{margin: 0;}
.experience_description .anticon svg{color: #11a151;}
.userdetailtab .description_wrap .ant-card-body{background: #fff;}
.title-tag-wrap{display: flex; align-items: center; justify-content: space-between; gap: 15px; margin: 0 0 20px;}
.title-tag-wrap .ant-typography{flex-shrink: 0; margin: 0;}
.lead { font-size: 15px !important; font-weight: 400 !important; line-height: 1.5;}
.media-body .nametitle{color: #455560; font-weight: 600; font-size: 22px;}
.media-body .emailicon{font-size: 14px; font-weight: 400; margin: 10px 0 0;}
.emailicon .anticon-mail{font-size: 12px; background: #f1f1f1; border-radius: 4px; width: 22px; height: 22px;}
.user-details-wrapper .singleuserimage{box-shadow: 0 4px 6px -1px #0000001a; border-radius: 15px; height: 350px; overflow: hidden; border: 2px solid #f5f5f5; padding: 0 !important;}
.user-details-wrapper .singleuserimage .ant-image{width: 100%; height: 100%;}
.user-details-wrapper .media-heading{color: #455560 !important; font-weight: 600; font-size: 22px;}
.userdetailwrap .singleuserdetail{display: flex; justify-content: center; align-items: center;}
.singleuserdetail{display: flex;}
.singleuserdetail .ant-card{margin: 0; flex-shrink: 1; flex-grow: 1;}
.user-details{display: flex;}
.address-tab .anticon{color: #21864b !important;}
.companyusr_detail{display: flex;}
.companyusr_detail .ant-card{flex-grow: 1; flex-shrink: 1; margin: 0;}
#rc-tabs-1-panel-4{padding: 0;}
.company_addresstab .ant-card{margin: 0; background: #f9f9f9;}
.company_addresstab .ant-card-body .ant-row{border-bottom: 1px dashed #dddddd; margin: 0 0 19px !important;}
.company_addresstab .ant-card-body span{color: #000 !important;}
.company_addresstab .ant-card .ant-card-body .ant-col svg{color: #11a151 !important;}
#rc-tabs-1-panel-5{padding: 0; background: #f9f9f9;}
#rc-tabs-1-panel-5 .ant-card{margin: 0;}
#rc-tabs-3-panel-1{background: transparent; box-shadow: none; padding: 0;}
.singleuserdetail .anticon{width: 24px; height: 24px; background: #f9f9f9; border-radius: 5px;}
.singleuserdetail .anticon svg{font-size: 13px;}
#rc-tabs-1-panel-1{padding: 0;}
#rc-tabs-1-panel-2{padding: 0}
#rc-tabs-1-panel-2 .ant-card{margin: 0; background: #f9f9f9;}
#rc-tabs-1-panel-3{padding: 0;}
#rc-tabs-1-panel-3 .ant-card{margin: 0; background: #f9f9f9;}
#rc-tabs-1-panel-6{padding: 0;}
#rc-tabs-1-panel-6 .ant-card{margin: 0;}
#rc-tabs-1-panel-6 #rc-tabs-5-panel-1{padding: 0; box-shadow: none;}
#rc-tabs-5-panel-1 .ant-card{margin: 0;}
#rc-tabs-1-panel-7{padding: 0;}
#rc-tabs-1-panel-7 .ant-card{border: none;}
#rc-tabs-1-panel-8{padding: 0;}
#rc-tabs-1-panel-8 .ant-card{margin: 0; background: #f9f9f9;}
.userdetails_icon{background-color: rgb(254, 179, 27); border-radius: 50px; width: 35px; height: 35px; margin: 0 auto 9px; padding: 2px 0 0 0; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;}
.userbadges .ant-ribbon-wrapper:nth-child(2) .userdetails_icon{background-color: #f11f92;}
.userbadges .ant-ribbon-wrapper:nth-child(3) .userdetails_icon{background-color: #21864b;}
.userbadges .ant-ribbon-wrapper:nth-child(4) .userdetails_icon{background-color: #d9010b;}
.ant-tabs-top .ant-tabs-content .ant-tabs-tabpane{padding: 0;}
.ant-tabs-top .ant-tabs-content .ant-tabs-tabpane .ant-card{margin: 0;}
.usereditform .ant-tabs-tabpane { background: #fff; padding: 30px 25px 0 !important;}
.userdetailsmain_wrapper .ant-tabs-content .ant-row:nth-child(2) {margin-top: 22px;}
.classifiedpost_data .ant-avatar{border-radius: 5px;}
.classifiedpostlist .ant-table-wrapper .ant-table-cell{padding: 10px 10px;}
.classifiedpostlist .ant-table-thead .ant-table-cell{background: #000; color: #fff;}
.classifiedpostlist .ant-table-wrapper .ant-table-cell div a{color: #000; text-decoration: underline;}
.classifiedpostlist .ant-table-wrapper table{border-radius: 12px 12px 12px 12px; overflow: hidden;}
.activitylist .ant-avatar{border-radius: 5px;}
